.navbar-back-container {
    position: fixed;
    padding: 40px;
    width: 260px;
    height: 100%;
    background: #fff;
}

.img-navBack-container img {
    height: 80px;
}

.navbar-back-container ul {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.navbar-back-container ul li {
    font-size: var(--font-size-p);
}

.navbar-back-container ul li a,
.data-admin span {
    color: var(--artimarq-color);
}

.footer-navbar-back {
    margin-top: 80px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.data-admin {
    display: flex;
    flex-direction: column;
}

.data-admin span:first-child {
    font-weight: var(--font-weight-bold);
}

.items-back-link{
    position: relative;
}

.items-back-link.active {
    font-weight: var(--font-weight-bold);
}