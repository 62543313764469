footer {
    position: absolute;
    bottom: 0;
    width: 100%;
}

.upFooter {
    padding: 30px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #eee;
}

.img-footer-container img {
    height: 100px;
}

.network-container {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.network-container span {
    font-size: 25px;
    font-weight: var(--font-weight-bold);
    color: var(--artimarq-color);
}

.icon-rs-footer {
    margin-left: 20px;
    height: 25px;
    fill: var(--artimarq-color);
}

.downFooter {
    display: flex;
    padding: 30px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #000;
}

.downFooter ul {
    margin-bottom: 20px;
    display: flex;
    gap: 30px;
}

.downFooter ul li a,
.downFooter span {
    color: #ccc;
}

.downFooter span {
    font-size: 13px;
}

.newsletter-input-container {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
}

.sunscribe-newsletter {
    display: flex;
}

.newsletter-input-container input {
    border: 1px solid #fff;
    font: #fff;
    height: 30px;
}

.subscrib-news-button {
    height: 30px;
    padding: 0 25px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .upFooter {
        padding: 30px 0 40px;
    }

    .network-container span {
        font-size: 20px;
    }

    .icon-rs-footer {
        height: 20px;
    }

    .downFooter {
        padding: 25px 0;
    }

    .downFooter ul {
        margin-bottom: 15px;
    }

    .downFooter span {
        font-size: 11px;
    }
}

@media screen and (max-width: 480px) {
    .upFooter {
        padding: 20px 0 20px;
    }

    .img-footer-container img {
        height: 60px;
    }

    .network-container {
        gap: 10px;
        margin-top: 10px;
    }

    .network-container span {
        font-size: 18px;
    }

    .icon-rs-footer {
        height: 18px;
    }

    .downFooter {
        padding: 20px 10px;
    }

    .downFooter ul {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .downFooter ul li a {
        font-size: 12px;
    }

    .downFooter span {
        font-size: 8px;
    }
}