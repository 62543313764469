.curtainsMenutitles {
    display: none;
}

@media screen and (max-width: 480px) {
    .curtainsMenuContainer {
        position: fixed;
        padding: 40px 20px;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        width: 20rem;
        background: #D9D9D9;
    }

    .curtainsMenuContainer.active {
        visibility: visible;
        transform: translateX(0);
        transition: var(--transition-menu-speed);
    }

    .curtainsMenuContainer.inactive {
        visibility: hidden;
        transform: translateX(-100%);
        transition: var(--transition-menu-speed);
    }

    .bg-curtains-menu {
        position: fixed;
        right: 0;
        top: 50px;
        height: 100%;
        width: 100%;
        background: var(--background-shadow);
        z-index: -1;
    }

    .bg-curtains-menu.active {
        visibility: visible;
        opacity: 1;
        transition: 0.3s ease-in;
    }

    .bg-curtains-menu.inactive {
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease-in;
    }

    .curtainsMenuContainer ul {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .curtainsMenuContainer ul li {
        color: var(--title-menu-color);
    }

    .curtainsMenuContainer ul li:first-child {
        font-weight: var(--font-weight-bold);
    }

    .devis-btn-menu {
        margin: 40px 0 auto;
    }

}

@media screen and (max-width: 360px) {
    .curtainsMenuContainer {
        padding: 20px 20px;
        width: 17rem;
    }

    .curtainsMenuContainer ul {
        gap: 15px;
    }

    .curtainsMenuContainer ul li {
        font-size: 14px;
    }
}