.desktop-advice {
    display: none;
}

.login-container {
    padding-top: 10rem;
}

.login-admin-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.login-admin-page form {
    width: 400px;
    margin: 0 auto;
    text-align: center;
}

.login-admin-page input[type="email"],
.login-admin-page input[type="password"],
.login-admin-page input[type="text"] {
    display: block;
    width: 100%;
    margin: 10px 0;
    padding: 12px 20px;
    border: 1px solid #000;
    background: transparent;
    border-radius: var(--border-radius);
    font-size: 16px;
}

.input-log-admin-container {
    position: relative;
}

.eyes-container {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.openEye.active {
    display: block;
    height: 10px;
}

.openEye.inactive {
    display: none;
}

.closeEye.active {
    display: block;
    height: 12px;
}

.closeEye.inactive {
    display: none;
}

.login-admin-page button {
    margin-top: 20px;
}

@media screen and (max-width: 1290px) {
    .desktop-advice {
        display: flex;
        justify-content: center;
        background: #000;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .desktop-advice p {
        margin: 20% 10px 0;
        color: #fff;
        font-size: 20px;
        text-align: center;
        font-weight: bold;
    }
}

@media screen and (max-width: 1024px) {
    .login-container {
        padding-top: 0;
    }

    .login-container svg {
        height: 150px;
    }
}

@media screen and (max-width: 834px) {
    .login-container svg {
        height: 100px;
    }
}

@media screen and (max-width: 480px) {
    .login-container h1 {
        font-size: 15px;
    }

    .login-admin-page form {
        width: 250px;
    }

    .login-admin-page form label {
        font-size: 14px;
    }

    .login-admin-page form input[type="email"],
    .login-admin-page form input[type="password"],
    .login-admin-page form input[type="text"] {
        padding: 6px 10px;
        border-radius: 60px;
        font-size: 14px;
    }

    .eyesContainerBackLogin {
        top: 10px;
    }

    .login-admin-page form button {
        margin-top: 15px;
    }

    .login-container svg {
        height: 70px;
    }

}

@media screen and (max-width: 375px) {
    .login-container h1 {
        font-size: 15px;
    }

    .login-admin-page form label {
        font-size: 13px;
    }
}