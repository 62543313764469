.bg-edite-customer {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--background-shadow);
}

.bg-edite-customer.active {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in;
}

.bg-edite-customer.inactive {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in;
}

.edite-customer-page {
    padding: 30px;
    border-radius: 10px;
    background-color: #fff
}