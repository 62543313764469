.link-Page {
    padding: 15px;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.Hero-link-page {
    background-size: cover;
    background-position: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.heroPageOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.content-link-page {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin-top: 5%;
    z-index: 2;
}

.header-content-link-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 30px;
}

.logo-link-page img {
    height: 100px;
}

.content-link-page h1 {
    text-transform: uppercase;
}

.content-link-page p {
    font-size: 16px;
}

.links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.link-item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    padding: 5px 0;
    width: 15rem;
    color: #fff;
    font-size: 16px;
    transition: all 0.3s ease;
}

.link-item:hover {
    background: rgba(255, 255, 255, 0.3);
    border: 2px solid rgba(255, 255, 255, 0.5);
    transform: scale(1.05);
}

.icon-network-link-page {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
}

.icon-rs-link-page {
    height: 25px;
    fill: #fff;
}

@media screen and (max-width: 480px) {
    .content-link-page {
        gap: 30px;
        margin-top: 10%;
    }

    .logo-link-page img {
        height: 70px;
    }

    .content-link-page p {
        font-size: 14px;
    }

    .icon-rs-link-page {
        height: 20px;
    }

    .link-item {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px) {

    .logo-link-page img {
        height: 50px;
    }
}