.loadingPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #bbb;
    z-index: 10;
}

.loader {
    width: 15rem;
    height: 15rem;
    border: 5px solid transparent;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite, changeColor 3s infinite;
}

@media screen and (max-width: 1024px) {
    .loader {
        width: 5rem;
        height: 5rem;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes changeColor {
    0% {
        border-top: 5px solid #3498db;
    }

    25% {
        border-top: 5px solid #e74c3c;
    }

    50% {
        border-top: 5px solid #2ecc71;
    }

    75% {
        border-top: 5px solid #f1c40f;
    }

    100% {
        border-top: 5px solid #3498db;
    }
}