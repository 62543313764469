.form-body-contact {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 630px;
}

.contact-form input,
.contact-form select {
    width: 300px;
}

.input-contact-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 7px;
}

.input-contact-container label {
    font-size: var(--font-size-label);
}

.civility-contact-container {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
}

.civility-opt {
    display: flex;
    align-items: center;
}

.civility-opt label input[type="radio"] {
    margin-right: 10px;
}

.civility-opt label.errorInput {
    color: red;
}

.civility-opt input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 12px;
    height: 12px;
    border: 2px solid #000;
    border-radius: 50%;
}

.civility-opt input[type="radio"].errorInput {
    border: 1px solid red;
}

.civility-opt input[type="radio"]:checked {
    background-color: #000;
    border-color: #000;
}

.message-container textarea {
    padding: 10px 5px;
    width: 620px;
    height: 320px;
    font-size: 15px;
    background: transparent;
    border: 1px solid #000;
}

.submit-contact-container {
    display: flex;
    justify-content: flex-end;
    width: 620px;
}




.check-box-container {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.using-cond-container,
.newsletter {
    display: flex;
    align-items: center;
    font-size: 12px;
}

.using-cond-container label a,
.newsletter label a {
    display: flex;
    color: #0346b2;
}

.checked-icon {
    visibility: hidden;
    position: absolute;
    height: 15px;
}

.checkbox-space {
    width: 30px;
    display: flex;
    align-items: center;
}

#acceptTerms,
#checknews {
    /* margin-top: 3px; */
    appearance: none;
    height: 15px;
    width: 15px;
    border-radius: 3px;
    border: 1px solid #000;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    z-index: 2;
}

#acceptTerms.errorInput {
    border: 1px solid red;
}

.label-using-cond-container.errorInput {
    color: red;
}

#acceptTerms:checked~.checked-icon,
#checknews:checked~.checked-icon {
    visibility: visible;
    color: rgba(0, 0, 0, .5);
}

@media screen and (max-width: 480px) {
    .form-body-contact {
        width: 300px;
    }

    .message-container textarea {
        width: 300px;
    }

    .submit-contact-container {
        margin-top: 20px;
        width: 300px;
    }
}

@media screen and (max-width: 320px) {
    .form-body-contact {
        width: 250px;
    }

    .input-contact-container input[type="text"],
    .input-contact-container input[type="email"] {
        width: 250px;
    }

    .message-container textarea {
        width: 250px;
    }

    .submit-contact-container {
        width: 250px;
    }
}