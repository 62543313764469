.devis-card-back {
  display: flex;
  width: 100%;
  height: 190px;
  padding: 0px 20px;
  border-radius: 3px;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border: 0.5px solid #BEBEBE;
  background: #FFF;
}

.devis-card-back li {
  flex: 1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1px;
  width: 100%;
}

.devis-card-back li.last-item {
  gap: 10px;
}

.card-btn {
  padding: 5px 0;
  width: 10rem;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background: #000;
  color: #fff;
}

.card-btn.inactive{
  background: rgb(88, 88, 88);
}

.card-btn.unconsulted {
  background: #0c54549e;
}

.orderCardExpired14 {
  color: orange;
}

.orderCardExpired30 {
  color: red;
}

/* switch button */
.switch {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 25px;
  border-radius: 25px;
  background-color: #ccc;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.switch.on {
  background-color: #4cd137;
}

.switch .toggle {
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  transition: transform 0.2s ease;
  transform: translateX(0);
}

.switch.on .toggle {
  transform: translateX(26px);
}

/* End switch button  */

.devis-card-back li img {
  width: 80px;
}

.firstWeekStyle {
  color: blueviolet;
}

.secondWeekStyle {
  color: orange;
}

.thirdWeekStyle {
  color: rgb(255, 85, 0);
}

.lastWeekStyle {
  color: red;
}