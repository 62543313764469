.Details-message-container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--background-shadow);
}

.Details-message-container.active {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in;
}

.Details-message-container.inactive {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in;
}

.message-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 30px;
    width: 50%;
    border-radius: 10px;
    background-color: #fff
}

.up-detail-message {
    word-wrap: break-word;
}

.message-content p,
.message-content textarea {
    padding: 7px;
    border-radius: 5px;
    border: 1px solid #000;
    color: #777;
    font-size: 15px;
}

.content-message-container {
    word-wrap: break-word;
    height: 10rem;
}

.footer-message-page {
    display: flex;
    justify-content: flex-end;
}

.btn-message-page {
    display: flex;
    gap: 10px;
}