.homeHeroUp {
    background-size: cover;
    background-position: center;
    height: 500px;
    position: relative;
}

.heroText {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding-left: 100px;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    width: 100%;
    gap: 20px;
}

.heroText h1 {
    font-size: var(--font-size-h1);
}

.heroText p {
    font-size: 16px;
    width: 95%;
}

.btnHomeBanner {
    transition: all var(--transition-speed) ease-in-out;
}

.btnHomeBanner:hover {
    background-color: #000;
    color: #fff;
}

.home-content {
    padding: var(--padding-page);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 110px;
}

.head-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.head-content h2 {
    font-size: 33px;
}

.head-content span {
    font-size: var(--font-size-span);
    color: var(--text-gray-color);
}

.products-container {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 40px;
}

.links-services {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 100px;
    font-size: 20px;
    font-weight: var(--font-weight-bold);
}

.links-services li {
    position: relative;
    color: var(--text-gray-color);
    cursor: pointer;
}

.links-services li::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--text-gray-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: all var(--transition-speed)
}

.links-services li:hover::after {
    transform: scaleX(1);
}

.home-text-container {
    position: relative;
    padding: 43px 100px;
    width: 100%;
    height: 20rem;
    border-radius: var(--border-radius);
    text-align: center;
    border: 1px solid #777;
}

.home-text-container .cross-contanier {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
}

.cross-contanier svg {
    width: 20px;
    height: 20px;
}

.home-text-container .cross {
    stroke: #000;
    stroke-width: 25px;
    fill-rule: evenodd;
}

.home-text-container h2 {
    margin-bottom: 30px;
    font-size: var(--font-size-h2);
}

.home-text-container p {
    color: var(--text-gray-color);
    line-height: var(--ligne-height);
    font-size: var(--font-size-p);
}

.engagement-container {
    display: flex;
    justify-content: center;
    gap: 80px;
}

.engagement {
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 200px;
    height: 170px;
    border-radius: var(--border-radius);
    background: rgba(119, 119, 119, 0.13);
}

.engagement img {
    height: 70px;
}

.text-container-eng {
    padding-top: 10px;
    text-align: center;
    height: 30%;
}

.engagement span {
    color: var(--text-gray-color);
    text-align: center;
    font-size: 14px;
    line-height: var(--ligne-height);
}

@media screen and (max-width: 1440px) {
    .homeHeroUp {
        height: 400px;
    }

    .home-content {
        gap: 80px;
    }

    .head-content {
        gap: 10px;
    }

    .head-content h2 {
        font-size: 28px;
    }

    .links-services {
        gap: 80px;
        font-size: 18px;
    }

    .home-text-container {
        padding: 25px 80px;
    }

    .home-text-container .cross-contanier {
        top: 15px;
        right: 15px;
    }

    .cross-contanier svg {
        width: 18px;
        height: 18px;
    }

    .home-text-container h2 {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 1280px) {
    .homeHeroUp {
        height: 350px;
    }

    .home-content {
        padding: 70px 100px 0;
        gap: 70px;
    }

    .head-content h2 {
        font-size: 25px;
    }

    .home-text-container {
        height: 17rem;
    }

    .engagement-container {
        gap: 100px;
    }

    .engagement {
        padding: 0 10px;
        width: 180px;
        height: 150px;
    }

    .engagement img {
        height: 50px;
    }
}

@media screen and (max-width: 834px) {
    .heroText {
        padding-left: 50px;
    }

    .heroText p {
        font-size: 15px;
    }

    .home-content {
        padding: 70px 50px 0;
    }

    .links-services {
        gap: 50px;
        font-size: 16px;
    }

    .home-text-container {
        padding: 25px 30px;
    }

    .engagement-container {
        gap: 50px;
    }
}

@media screen and (max-width: 480px) {
    .homeHeroUp {
        height: 500px;
    }

    .heroText {
        padding: 0;
        align-items: center;
        gap: 10px;
        top: 55%;
        transform: translateY(0%);
    }

    .heroText p {
        text-align: center;
        font-size: 14px;
    }

    .head-content h2 {
        font-size: 22px;
    }

    .home-content {
        padding: 50px 20px 0;
        gap: 50px;
    }

    .products-container {
        flex-wrap: wrap;
    }

    .links-services {
        flex-direction: column;
        align-items: center;
        font-size: 16px;
        gap: 25px;
    }

    .home-text-container {
        padding: 10px 30px;
        height: 19rem;
    }

    .cross-contanier svg {
        width: 15px;
        height: 15px;
    }

    .home-text-container .cross-contanier {
        top: 10px;
        right: 10px;
    }

    .home-text-container h2 {
        margin-bottom: 15px;
    }

    .engagement-container {
        gap: 20px;
    }

    .engagement {
        padding: 0 5px;
        width: 130px;
        height: 140px;
    }

    .engagement img {
        height: 30px;
    }

    .engagement span {
        font-size: 11px;
    }
}

@media screen and (max-width: 430px) {
    .links-services {
        gap: 20px;
    }

    .cross-contanier svg {
        width: 15px;
        height: 15px;
    }

    .home-text-container {
        padding: 10px 10px;
        height: 19rem;
    }

    .engagement-container {
        gap: 15px;
    }

    .engagement {
        padding: 0 5px;
        width: 100px;
        height: 110px;
        line-height: 10px;
    }

    .text-container-eng {
        height: 40%;
    }


    .engagement span {
        font-size: 11px;
    }
}

@media screen and (max-width: 375px) {
    .home-text-container {
        height: 21rem;
    }
}

@media screen and (max-width: 360px) {
    .products-container {
        gap: 10px;
    }

    .home-text-container {
        padding: 10px 10px;
    }
}

@media screen and (max-width: 320px) {
    .homeHeroUp {
        height: 350px;
    }

    .heroText {
        gap: 5px;
        top: 50%;
    }

    .engagement-container {
        flex-direction: column;
    }

    .engagement {
        width: 100%;
        height: 90px;
        padding: 0 15px;
    }

    .home-text-container {
        height: 24rem;
    }

    .text-container-eng {
        height: 30%;
    }
}