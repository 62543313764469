.container-edite-btn {
    margin-top: 50px;
    display: flex;
    width: 53rem;
    justify-content: flex-end;
}

.edite-btn {
    display: flex;
    gap: 10px;
}