.unsubscribe-page {
    display: flex;
    align-items: center;
    flex-direction: column;
}

h1 {
    font-size: 2em;
    margin-bottom: 10px;
}

p {
    font-size: 1.2em;
    margin-bottom: 15px;
}

.unsubscribe-page a {
    color: var(--link-color);
}

@media only screen and (min-width: 1024px) {
    h1 {
        font-size: 3em;
    }

    p {
        font-size: 1.4em;
    }
}

@media only screen and (min-width: 600px) {
    h1 {
        font-size: 2.5em;
    }

    p {
        font-size: 1.3em;
    }
}