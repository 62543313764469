.blog-content {
    padding: 50px 200px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 80px;
}

.articles-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(50px, auto);
    align-items: center;
    justify-content: center;
}

.content-empty-blog {
    margin: 100px;
    text-align: center;
}

/* Articles page */

.article-page {
    padding: 50px 250px 0;
}

.progress-bar {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--link-color);
    height: 10px;
}

.progress-bar::-webkit-progress-bar {
    background-color: #f0f0f0;
}

.progress-bar::-webkit-progress-value {
    background-color: var(--link-color);
}

#articleContent {
    padding: 10px;
    border: 1px solid #000;
    background: transparent;
    font-size: 14px;
}

@media screen and (max-width: 1366px) {
    .blog-content {
        padding: 50px 150px 0;
    }
}

@media screen and (max-width: 1280px) {
    .blog-content {
        padding: 50px 100px 0;
    }
}

@media screen and (max-width: 1024px) {
    .articles-content {
        grid-template-columns: repeat(2, 1fr);
        grid-auto-rows: minmax(50px, auto);
    }

    .blog-content {
        padding: 50px 50px 0;
    }

    .article-page {
        padding: 50px 50px 0;
    }
}

@media screen and (max-width: 480px) {
    .blog-content {
        padding: 50px 20px 0;
    }

    .article-page {
        padding: 50px 10px 0;
    }

    .articles-content {
        grid-template-columns: repeat(1, 1fr);
    }
}