.back-page-content {
    margin-left: 260px;
    padding: 40px;
}

.admin-content-page {
    margin-top: 60px;
}

.devis-back-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    border-radius: 3px;
    background: #F3F3F3;
    gap: 10px;
}