.polities-pages {
    padding: 70px 300px 0;
}

.polities-pages,
.head-policies-pages,
.body-policies-page,
.section-policies,
.centent-cgv {
    display: flex;
    flex-direction: column;
}

.polities-pages {
    gap: 40px;
}

.head-policies-pages,
.body-policies-page,
.centent-cgv {
    gap: 30px;
}

.head-policies-pages {
    padding-bottom: 40px;
    border-bottom: 1px solid #000;
}

.section-policies {
    gap: 10px;
}

.section-policies h3,
.section-policies h4,
.section-policies h1 {
    color: #222;
}

.polities-pages p {
    color: var(--text-gray-color);
    font-size: var(--font-size-p);
}

.polities-pages td {
    padding: 5px;
}

.polities-pages table,
.polities-pages th,
.polities-pages td {
    font-size: 13px;
    color: var(--text-gray-color);
    border: 1px solid #111;
    border-collapse: collapse;
}

.polities-pages th {
    background: rgb(9, 92, 245);
    color: #fff;
}

.datas-table {
    width: 80%;
}

.datas-table th {
    padding: 20px 5px;
}

.cookies-table th {
    padding: 10px 5px;
}

@media screen and (max-width: 1366px) {
    .polities-pages {
        padding: 70px 100px 0;
    }
}


@media screen and (max-width: 1194px) {
    .polities-pages {
        padding: 70px 50px 0;
    }

    .datas-table {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .polities-pages {
        padding: 30px 30px 0;
        overflow: hidden;
    }

    .polities-pages h1 {
        font-size: 18px;
    }

    .polities-pages h3 {
        font-size: 15px;
    }

    .polities-pages table,
    .polities-pages th,
    .polities-pages td {
        font-size: 10px;
    }

    .datas-table th {
        padding: 5px;
    }

    .cookies-table th {
        padding: 5px;
    }
}