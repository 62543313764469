.section-back {
    display: flex;
    width: 937px;
    padding: 0 50px 20px 10px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #000;
}

.section-back span {
    font-size: 30px;
    font-weight: var(--font-weight-ligth);
}

.section-back div {
    display: flex;
    align-items: center;
    gap: 30px;
}

.Link-nav-container {
    position: relative;
}

.link-section-back.active {
    padding: 7px 10px;
    border-radius: 30px;
    color: #eee;
    background: #000;
}