.legaleNotice {
    padding: 50px 200px 0;
}

.mentions-legales-container {
    padding: 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    line-height: 1.5;
}

.mentions-legales-container h1 {
    margin-bottom: 20px;
}

.mentions-legales-container h2 {
    margin-bottom: 10px;
}

.mentions-legales-container p {
    margin-bottom: 15px;
    font-weight: var(--font-size-normal);
    font-size: var(--font-size-p);
}

.mentions-legales-container a {
    font-weight: var(--font-size-normal);
    color: #0346b2;
}

@media screen and (max-width: 1024px) {
    .legaleNotice {
        padding: 50px 50px 0;
    }
}

@media screen and (max-width: 480px) {
    .legaleNotice {
        padding: 50px 10px 0;
    }

    .mentions-legales-container {
        padding: 10px;
        font-size: 12px;
    }

    .mentions-legales-container h1 {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .mentions-legales-container h2 {
        font-size: 14px;
        margin-bottom: 5px;
    }

    .mentions-legales-container p {
        margin-bottom: 10px;
    }

}