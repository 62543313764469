.products-pages {
    display: flex;
    flex-direction: column;
    gap: 100px;
}

.head-products-pages {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
}

.head-products-pages h1 {
    font-size: 26px;
    width: 60rem;
    text-align: center;
    font-size: var(--font-size-h2);
}

.head-products-pages p {
    color: var(--text-gray-color);
    line-height: var(--ligne-height);
    font-size: var(--font-size-p);
    letter-spacing: 1px;
    text-align: center;
    width: 49rem;
}

.body-products-pages {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.content-product {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 80px;
}

.content-more-4-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 80px;
    grid-auto-rows: minmax(100px, auto);
    align-items: center;
    justify-content: center;
}

.contact-button-product {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-button-product span {
    font-weight: var(--font-weight-bold);
}

.contentEmpty a {
    color: var(--link-color);
}

@media screen and (max-width: 1024px) {
    .head-products-pages h1 {
        width: 100%;
    }

    .head-products-pages p {
        width: 35rem;
    }

    .content-product {
        gap: 40px;
    }

    .content-more-4-product {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 40px;
    }
}

@media screen and (max-width: 480px) {
    .products-pages {
        gap: 50px;
    }

    .head-products-pages h1 {
        font-size: 18px;
    }

    .head-products-pages {
        gap: 10px;
    }

    .head-products-pages p {
        width: 100%;
    }

    .content-product {
        gap: 20px;
    }

    .content-more-4-product {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 20px;
    }

    .contact-button-product {
        gap: 15px;
    }

    .contact-button-product span {
        font-size: 15px;
    }
}

@media screen and (max-width: 320px) {
    .head-products-pages {
        gap: 10px;
    }

    .content-more-4-product {
        grid-template-columns: repeat(1, 1fr);
    }
}