.input-devis-up-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.input-devis-up-container input,
.input-devis-up-container select {
    width: 265px;
}

.section-input-devis {
    display: flex;
    gap: 20px;
}

.input-devis-bloc {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.presta-container,
.discount_devis_container,
.shipping_devis_container {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.input-presta-container {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

#Civility,
#validity,
#zip,
[id^="qty-input"],
[id^="price-select"],
[id^="vat-select"] {
    width: 100px;
}

.input-presta-container textarea {
    padding: 10px;
    border: 1px solid #000;
    background: transparent;
}

.text-too-long {
    color: red;
}


.remove-presta-button {
    background: transparent;
    font-size: 13px;
    font-weight: var(--font-weight-bold);
}

.footer-devis-page {
    display: flex;
    justify-content: flex-end;
    width: 937px;
}

.btn-devis-page {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

.media-man-bloc{
    gap: 100px;
}

.media-visu {
    margin: 30px 0;
    height: 150px;
}

.media-visu img {
    max-height: 100%;
}