.bg-contact-customer{
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--background-shadow);
}

.bg-contact-customer.active {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in;
}

.bg-contact-customer.inactive {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in;
}

.contact-Back-Container {
    padding: 30px;
    border-radius: 10px;
    background-color: var(--bg-color);
    display: flex;
    flex-direction: column;
}

.contact-Back-Container input {
    padding: 5px 10px;
    margin-bottom: 30px;
    font-size: 14px;
    border: 1px solid #000;
    border-radius: 10px;
}

.contact-Back-Container textarea {
    margin-bottom: 20px;
    font-size: 14px;
    padding: 10px;
    border: 1px solid #000;
    border-radius: 10px;
    background: transparent;
}

.btn-Contact-Gest{
    display: flex;
    justify-content: flex-end;
}

.btn-Contact-Gest button:first-child {
    margin-right: 10px;
}