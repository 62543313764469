.page-faq {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.ques-answ-container {
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    border-bottom: 1px solid #000;
    gap: 10px;
}

.question-container {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.question-container span {
    font-size: 18px;
    font-weight: var(--font-weight-ligth);
}

.question-container svg {
    height: 10px;
    transition: var(--transition-speed);
}

.rotate {
    transform: rotate(180deg);
}

.answer-container {
    max-height: 0;
    overflow: hidden;
    transition: var(--transition-speed);
}

.answer-container.show {
    max-height: 1000px;
    transition: var(--transition-speed);
}

.answer-container p {
    padding-left: 15px;
    line-height: 20px;
    width: 49rem;
    font-size: var(--font-size-p);
    color: var(--text-gray-color);
}

.contact-btn-faq {
    display: grid;
    gap: 20px;
}

@media screen and (max-width: 480px) {
    .page-faq {
        gap: 30px;
    }

    .page-faq h1 {
        font-size: 18px;
    }

    .ques-answ-container {
        padding: 10px 0;
    }

    .question-container span {
        font-size: 15px;
    }

    .question-container svg {
        height: 7px;
    }

    .answer-container p {
        line-height: 15px;
        width: 20rem;
    }

    .contact-btn-faq {
        font-size: 12px;
    }

}

@media screen and (max-width: 360px) {
    .question-container span {
        font-size: 13px;
    }

    .answer-container p {
        width: 17rem;
    }

    .contact-btn-faq {
        font-size: 10px;
    }
}