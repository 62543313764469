.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #f6f6f6;
    padding: 15px 30px;
    text-align: center;
    z-index: 1000;
  }
  
  .cookie-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 50px;
  }

  @media screen and (max-width: 1024px) {
    .cookie-content {
      flex-direction: column;
      gap: 10px;
    }
}