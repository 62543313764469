.productCardContainer {
    width: 300px;
    text-transform: uppercase;
}

.warpper {
    position: relative;
    height: 400px;
    background-color: #dedede;
    border: 1px solid #e0e0e0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
}

.imgProductContainer {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    width: 100%;
    transition: all var(--transition-speed);
}

.productCardOverlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all var(--transition-speed);
}

.productCardContainer:hover .productCardOverlay {
    opacity: 1;
}

.productCardText {
    color: white;
    font-size: 18px;
    text-align: center;
}

@media screen and (max-width: 1440px) {
    .productCardContainer {
        width: 200px;
    }

    .warpper {
        height: 300px;
    }

    .productCardText {
        font-size: 16px;
    }

}

@media screen and (max-width: 1024px) {
    .warpper {
        height: 250px;
    }
}

@media screen and (max-width: 834px) {
    .productCardContainer {
        width: 170px;
    }

    .warpper {
        height: 220px;
    }
}

@media screen and (max-width: 480px) {
    .productCardContainer {
        width: 150px;
    }

    .warpper {
        height: 200px;
    }
}