.title-contact {
    margin-bottom: 60px;
}

.contact-page-content {
    display: flex;
}

.left-side-contact {
    position: relative;
    width: 30%;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.left-side-contact::after {
    content: '';
    position: absolute;
    right: 0;
    width: 2px;
    height: 50%;
    border-radius: 10px;
    background-color: #000;
}

.infos-contact-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.infos-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.infos-container a {
    color: var(--link-color);
}

.networks-contact {
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.networks-contact span {
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    color: var(--artimarq-color);
}

.icon-rs-contact {
    margin-right: 20px;
    height: 20px;
    fill: var(--artimarq-color);
}

.right-side-contact {
    padding-left: 200px;
}

@media screen and (max-width: 1440px) {
    .right-side-contact {
        padding-left: 100px;
    }
}

@media screen and (max-width: 1280px) {
    .left-side-contact::after {
        display: none;
    }

    .right-side-contact {
        padding-left: 50px;
    }
}

@media screen and (max-width: 1024px) {
    .contact-page-content {
        flex-direction: column;
        gap: 50px;
    }

    .left-side-contact {
        width: 100%;
    }

    .right-side-contact {
        padding: 50px 0 0 0;
        position: relative;
    }

    .right-side-contact::after {
        content: '';
        position: absolute;
        top: 0;
        width: 70%;
        height: 2px;
        border-radius: 10px;
        background-color: #000;
    }
}

@media screen and (max-width: 480px) {
    .title-contact {
        margin-bottom: 30px;
        font-size: 18px;
    }

    .left-side-contact {
        gap: 30px;
    }

    .networks-contact {
        gap: 10px;
    }

    .networks-contact span {
        font-size: 14px;
    }

    .icon-rs-contact {
        height: 14px;
    }
}