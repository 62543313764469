@charset "utf-8";

:root {
  --font-weight-ligth: lighter;
  --font-weight-normal: normal;
  --font-weight-bold: bold;
  --font-size-titles: 18px;
  --font-size-label: 14px;
  --artimarq-color: #1E3959;
  --text-gray-color: #777;
  --title-menu-color: #3B4559;
  --background-shadow: rgba(0, 0, 0, .3);
  --link-color: rgb(0, 94, 255);
  --border-radius: 10px;
  --padding-page: 110px 200px 0;
  --transition-speed: 0.3s ease-in;
  --transition-menu-speed: 0.5s ease-in;
  --ligne-height: 25px;
  --ligne-height-nd: 23px;
  --bg-color: #eee;
  --font-size-h1: 52px;
  --font-size-h2: 33px;
  --font-size-p: 16px;
  --font-size-span: 18px;
  --font-size-ratio: 1.618;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  box-sizing: border-box;
  /* box-shadow: 0 0 0 3px red inset; */
}

li,
a {
  list-style-type: none;
  text-decoration: none;
  color: #000;
}

body {
  font-size: 15px;
  position: relative;
  padding-bottom: 350px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #000;
  letter-spacing: 1px;
  background: var(--bg-color);
  font-family: 'Inter', Arial, Helvetica, sans-serif;
}

.body-website {
  padding: 80px 0 100px;
  flex: 1;
}

.body-website_back {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.classic-page {
  padding: 50px 150px 0;
}

.btn-upload-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12rem;
  height: 2rem;
  background: #000;
  color: #fff;
}

.white-button,
.black-button,
.btn-upload-img {
  border-radius: 3px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
}

.white-button {
  padding: 10px 25px;
  background: #fff;
}

.black-button {
  padding: 10px 25px;
  background: #000;
  color: #fff;
}

.btn-back{
  margin-bottom: 5rem;
}

.transparent-button {
  font-size: 16px;
  cursor: pointer;
  background: transparent;
}

input:not([type="radio"]):not([type="checkbox"]),
select {
  padding: 0 10px;
  height: 30px;
  font-size: 15px;
  border: 1px solid #000;
  background: transparent;
}

input[type="checkbox"]:not(#acceptTerms, #checknews, .param-text-input),
input[type="radio"]:not(.radioCiv, .radio-civ-leads, .radioCivDevis, .param-text-input) {
  display: none;
}

.SubMenu.active {
  visibility: visible;
  opacity: 1;
  transition: 0.2s ease-in;
}

.SubMenu.inactive {
  visibility: hidden;
  opacity: 0;
  transition: 0.2s ease-in;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
}

.alertSuccess {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: var(--font-weight-bold);
  color: #666;
  animation: 0.6s succesDisplay;
}

.errorInputConfig {
  font-size: 11px;
  color: red;
  width: 300px;
}

.badgeNewOrder {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: bold;
}

@media screen and (max-width: 1440px) {
  :root {
    --font-size-h1: 42px;
    --font-size-h2: 28px;
    --font-size-p: 16px;
    --font-size-span: 16px;
  }
}

@media screen and (max-width: 1366px) {
  :root {
    --ligne-height: 20px;
    --padding-page: 80px 200px 0;
  }
}

@media screen and (max-width: 1280px) {
  :root {
    --padding-page: 80px 150px 0;
    --font-size-h1: 32px;
    --font-size-h2: 20px;
    --font-size-p: 13px;
    --font-size-span: 14px;
  }

  body {
    padding-bottom: 300px;
  }

  .white-button,
  .black-button {
    padding: 7px 18px;
    font-size: 11px;
  }

  .body-website {
    padding: 60px 0 100px;
    flex: 1;
  }
}

@media screen and (max-width: 834px) {
  :root {
    --font-size-h1: 25px;
    --font-size-h2: 25px;
    --font-size-p: 15px;
    --font-size-span: 15px;
  }

  .classic-page {
    padding: 50px 80px 0;
  }
}

@media screen and (max-width: 480px) {
  :root {
    --ligne-height: 15px;
    --font-size-label: 13px;
    --border-radius: 3px;
    --font-size-h1: 25px;
    --font-size-h2: 18px;
    --font-size-p: 13px;
    --font-size-span: 18px;
  }

  .body-website {
    padding: 50px 0 120px;
    flex: 1;
  }

  .classic-page {
    padding: 30px 20px 0;
  }
}

@media screen and (max-width: 360px) {
  :root {
    --font-size-h1: 20px;
    --font-size-h2: 15px;
  }
}

@media screen and (max-width: 320px) {
  :root {
    --font-size-h1: 20px;
    --font-size-h2: 13px;
  }
}

@keyframes succesDisplay {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}