.blog-hero {
    background-size: cover;
    background-position: center;
    height: 450px;
    position: relative;
}

.hero-blog-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    width: 100%;
    gap: 20px;
}

.hero-blog-text h1 {
    font-size: var(--font-size-h1);
}

.hero-blog-text p {
    font-size: 16px;
    color: #fff;
    text-align: center;
}

.blog-header {
    padding: 80px 0 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.blog-header ul {
    display: flex;
    justify-content: center;
    gap: 50px;
    flex-wrap: wrap;
}

.blog-header ul li a {
    position: relative;
    color: #000;
}

.blog-header ul li a::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -10%;
    width: 120%;
    height: 2px;
    background: #000;
    transition: all var(--transition-speed);
    opacity: 0;
    visibility: hidden;
}

.blog-header ul li a:hover::after {
    opacity: 1;
    visibility: visible;
}

.link-menu-blog {
    position: relative;
}

.link-menu-blog.active::after {
    opacity: 1;
    visibility: visible;
}

@media screen and (max-width: 834px) {
    .blog-header {
        padding: 50px 0 0;
    }

    .blog-header ul {
        gap: 20px;
    }

    .blog-header ul li a::after {
        left: 0;
        width: 100%;
    }
}

@media screen and (max-width: 834px) {
    .blog-header ul {
        flex-direction: column;
        align-items: center;
    }
}