.blog-card {
    max-width: 400px;
    margin: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-in-out;
}

.img-blog-container {
    overflow: hidden;
    background-size: cover;
    background-position: center;
    height: 200px;
}

.blog-card:hover {
    transform: scale(1.01);
}

.card-content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.blog-card h2,
.blog-card p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.blog-card h2 {
    font-size: 1.5rem;
    -webkit-line-clamp: 2;
}

.blog-card p {
    font-size: 1rem;
    color: #555;
    -webkit-line-clamp: 4;
}

.card-content button {
    width: 100%;
}

@media screen and (max-width: 834px) {
    .blog-card {
        margin: 5px;
    }

    .card-content {
        padding: 10px;
        gap: 5px;
    }

    h2 {
        font-size: 1rem;
    }

    p {
        font-size: .7rem;
    }
}

@media screen and (max-width: 480px) {
    .img-blog-container {
        height: 100px;
    }

    .blog-card {
        margin: 10px 0;
    }
}