.bg-pupop {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--background-shadow);
}

.bg-pupop.active {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in;
}

.bg-pupop.inactive {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in;
}

.popup-container {
    padding: 30px;
    width: 30%;
    border-radius: 10px;
    background-color: #fff
}

.popup-content {
    margin-bottom: 20px;
}

.popup-content p {
    font-size: 14px;
    text-transform: none;
}

.btn-popup-container {
    padding-top: 30px;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: flex-end;
    border-top: 1px solid #ccc;
}

.btnPopup {
    padding: 3px 7px;
}

.sub-btn-popop-container {
    width: 15.5rem;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 1024px) {
    .popup-container {
        width: 60%;
    }
}

@media screen and (max-width: 480px) {
    .popup-container {
        width: 90%;
    }

    .sub-btn-popop-container {
        width: 9rem;
    }
}

@media screen and (max-width: 375px) {
    .popup-content p {
        font-size: 12px;
    }

}