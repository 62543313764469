.bg-details-order {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: var(--background-shadow);
}

.bg-details-order.active {
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in;
}

.bg-details-order.inactive {
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in;
}

.details-order-container {
    overflow-y: scroll;
    padding: 30px;
    width: 50%;
    height: 60vh;
    border-radius: 10px;
    background-color: #fff
}

.details-content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.customer-container,
.textile-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.details-content span {
    font-weight: bold;
}

.data-pair {
    word-wrap: break-word;
}

ul {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.details-content img {
    width: 200px;
}

.btn-container-detail {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}