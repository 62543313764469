.marq-explain-container {
    position: relative;
    background-size: cover;
    background-position: center;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.section-marq-title {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 100px;
    font-size: 20px;
    font-weight: var(--font-weight-bold);
}

.section-marq-title li {
    position: relative;
    color: var(--text-gray-color);
    cursor: pointer;
}

.section-marq-title li::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: var(--text-gray-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: all var(--transition-speed)
}

.section-marq-title li:hover::after {
    transform: scaleX(1);
}

.body-marq-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.body-marq-page p {
    color: #777;
    line-height: var(--ligne-height);
    text-align: center;
    font-size: var(--font-size-p);
    width: 70rem;
}

.body-marq-page div {
    display: flex;
    flex-direction: column;
    gap: 30px;
    text-align: center;
}

.body-marq-page div:first-child {
    margin-bottom: 30px;
}

@media screen and (max-width: 1024px) {
    .body-marq-page p {
        width: 50rem;
    }
}

@media screen and (max-width: 834px) {
    .marq-explain-container {
        height: 400px;
    }

    .section-marq-title {
        gap: 50px;
        font-size: 20px;
    }

    .body-marq-page p {
        width: 40rem;
    }
}

@media screen and (max-width: 480px) {
    .marq-explain-container {
        height: 400px;
    }

    .section-marq-title {
        gap: 10px;
        font-size: 15px;
        flex-wrap: wrap
    }

    .body-marq-page h2 {
        font-size: 18px;
    }

    .body-marq-page p {
        width: 100%;
    }

    .section-marq-title li.selected-tech {
        border-bottom: .5px solid var(--text-gray-color);
        border-radius: var(--border-radius);
    }
}

@media screen and (max-width: 375px) {
    .marq-explain-container {
        height: 300px;
    }

    .section-marq-title {
        font-size: 13px;
    }

    .body-marq-page h2 {
        font-size: 14px;
    }
}

@media screen and (max-width: 320px) {
    .marq-explain-container {
        height: 200px;
    }

    .section-marq-title {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}