.custom-page {
    display: flex;
}

.left-side-custom {
    width: 50%;
}

.content-container-left {
    position: fixed;
}

.img-product-container {
    width: 30rem;
    height: 30rem;
    overflow: hidden;
    background-color: #dedede;
}

.img-product-container img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
}

/* rigth side */

.right-side-custom {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 50%;
}

.custom-param-container {
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.head-custom-param {
    margin-bottom: 40px;
    display: inline-flex;
    padding: 10px 0px 20px 10px;
    align-items: flex-start;
    gap: 10px;
    width: 30rem;
    border-bottom: 3px solid #000;
}

.head-custom-param span {
    font-size: var(--font-size-span);
}

.body-custom-param label {
    font-size: var(--font-size-p);
}

.container-param {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.body-custom-param {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Color style border: 1px solid red;*/

.color-container {
    display: flex;
    gap: 10px;
    width: 620px;
    flex-wrap: wrap;
}

.color-container label {
    padding: 12px;
    border-radius: 5px;
    filter: saturate(50%);
    cursor: pointer;
    border: 0.1px solid #aaa;
    transition: all var(--transition-speed);
}

.color-container input[type="radio"] {
    display: none;
}

.selected-color {
    transform: scale(1.2);
}

/* End color style */

.options-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.options-items {
    display: flex;
    justify-content: space-between;
    width: 350px;
}

.options-items label {
    cursor: pointer;
}

.options-items input[type="radio"],
.options-items input[type="checkbox"] {
    margin-right: 30px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 15px;
    height: 15px;
    border: 1px solid #000;
    border-radius: 5px;
    transition: all var(--transition-speed);
}

.options-items input[type="radio"]:checked,
.options-items input[type="checkbox"]:checked {
    border: 5px solid #000;
}

/* Zone, technics, delay */

.zones-container,
.technic-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    font-size: 13px;
    width: 40rem;
}

.zones-container label,
.technic-container label {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    cursor: pointer;
    border-radius: var(--border-radius);
    transition: all var(--transition-speed);
}

.zones-container label {
    padding: 15px 10px;
}

.technic-container label {
    padding: 7px 10px;
}

.zones-container img {
    height: 70px;
    width: auto;
}

.selected-background {
    background: rgba(0, 0, 0, 0.1);
    color: #ffffff;
}

/* End zone, technics, delay */

.sizes-container {
    display: flex;
    flex-wrap: wrap;
    gap: 35px;
}

.sizes-items {
    display: flex;
    align-items: center;
}

.size-label-container {
    width: 30px;
}

.sizes-items label {
    cursor: pointer;
}

.sizes-items input[type="number"] {
    margin-left: 10px;
    padding: 7px 10px;
    width: 100px;
}

.sizes-items input[type="radio"]:checked {
    border: 5px solid #000;
}

@media screen and (max-width: 1440px) {
    .img-product-container {
        width: 20rem;
        height: 20rem;
    }

    .color-container label {
        padding: 9px;
    }

    .color-container input[type="radio"] {
        display: none;
    }

    .submit-devis-container {
        width: 30rem;
    }
}

@media screen and (max-width: 1440px) {
    .img-product-container {
        width: 20rem;
        height: 20rem;
    }

    .color-container label {
        padding: 9px;
    }

    .color-container input[type="radio"] {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .head-custom-param {
        margin-bottom: 30px;
        width: 20rem;
    }

    .color-container {
        width: 400px;
    }

    .zones-container,
    .technic-container {
        width: 25rem;
    }

    .submit-devis-container {
        width: 25rem;
    }
}

@media screen and (max-width: 834px) {
    .img-product-container {
        width: 15rem;
        height: 15rem;
    }

    .custom-param-container {
        gap: 20px;
    }

    .color-container {
        width: 300px;
    }

    .zones-container,
    .technic-container {
        width: 20rem;
    }

    .submit-devis-container {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .custom-page {
        flex-direction: column;
        align-items: center;
    }

    .left-side-custom {
        width: 100%;
    }

    .content-container-left {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .img-product-container {
        width: 20rem;
        height: 20rem;
    }

    .container-param {
        gap: 20px;
    }

    .body-custom-param {
        gap: 10px;
    }

    .right-side-custom {
        padding-top: 10px;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

    .custom-param-container {
        gap: 20px;
    }

    .head-custom-param {
        margin-bottom: 10px;
        padding: 10px 0px 5px 10px;
        border-bottom: 2px solid #000;
    }

    .color-container {
        width: 100%;
        gap: 5px;
    }

    .color-container label {
        padding: 7px;
    }

    .options-items input[type="radio"],
    .options-items input[type="checkbox"] {
        width: 10px;
        height: 10px;
        border-radius: 0;
    }

    .options-items input[type="radio"]:checked,
    .options-items input[type="checkbox"]:checked {
        border: 3px solid #000;
    }

    .zones-container,
    .technic-container {
        width: 100%;
    }

    .zones-container label {
        padding: 10px 7px;
    }

    .zones-container span {
        font-size: 10px;
    }

    .technic-container label {
        padding: 3px 7px;
        font-size: 11px;
    }

    .sizes-container {
        gap: 20px;
    }

    .sizes-items input[type="number"] {
        margin-left: 0;
        width: 80px;
        font-size: 13px;
    }

    .submit-devis-container {
        margin-top: 20px;
        width: 20rem;
    }
}

@media screen and (max-width: 320px) {
    .head-custom-param {
        width: 15rem;
    }

    .head-custom-param {
        margin-bottom: 10px;
        padding: 10px 0px 5px 10px;
        border-bottom: 2px solid #000;
    }

    .color-container {
        width: 250px;
    }

    .options-items {
        width: 100%;
    }

    .sizes-items input[type="number"] {
        width: 70px;
        height: 20px;
    }

    .submit-devis-container {
        width: 15rem;
    }
}