.navBar {
    position: relative;
    display: flex;
    position: fixed;
    padding: 0px 50px;
    align-items: center;
    top: 0;
    height: 80px;
    width: 100%;
    background-color: #eee;
    z-index: 5;
}

.menu-curtains-btn {
    display: none;
}

.menu-btn-burger {
    width: 25px;
    height: 2px;
    border-radius: 50px;
    background: #000;
    transition: var(--transition-menu-speed);
}

.menu-btn-burger::before,
.menu-btn-burger::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 2px;
    border-radius: 50px;
    background: #000;
    transition: var(--transition-speed);
}

.menu-btn-burger::before {
    transform: translateY(-7px);
}

.menu-btn-burger::after {
    transform: translateY(7px);
}

.btn-crossed .menu-btn-burger {
    transform: translateX(-10px);
    background: transparent;
}

.btn-crossed .menu-btn-burger::before {
    transform: rotate(45deg) translate(10px, -10px);
}

.btn-crossed .menu-btn-burger::after {
    transform: rotate(-45deg) translate(10px, 10px);
}

.nav-left-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 60px;
}

.img-nav-container img {
    height: 60px;
}

.menu-button ul li a,
.menu-button button,
.def-link a {
    color: var(--artimarq-color);
}

.nav-right-container {
    margin-left: auto;
}

.SubMenu {
    display: block;
    position: absolute;
    padding: 5px 280px 50px;
    left: 0px;
    top: 80px;
    width: 100%;
    background: #fff;
    transition: 0.6s ease-in;
    z-index: 3;
}

.menu-button ul li {
    color: var(--title-menu-color);
    margin-top: 20px;
}

.product-img-pastille {
    display: flex;
    justify-content: center;
    gap: 10px;
    color: white;
    padding: 20px;
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 5;
    background-color: rgba(51, 51, 51, .7);
}

.accept-button {
    background-color: #0074cc;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

@media screen and (max-width: 1280px) {
    .navBar {
        height: 60px;
    }

    .nav-left-container {
        gap: 40px;
    }

    .img-nav-container img {
        height: 50px;
    }

    .menu-button button,
    .def-link a {
        font-size: 13px;
    }

    .SubMenu {
        padding: 0px 200px 30px;
        top: 60px;
    }

    .menu-button ul li {
        margin-top: 15px;
        font-size: 13px;
    }

    .product-img-pastille {
        top: 60px;
    }
}

@media screen and (max-width: 834px) {
    .product-img-pastille {
        flex-direction: column;
    }
}

@media screen and (max-width: 480px) {
    .navBar {
        height: 50px;
        padding: 0px 20px;
    }

    .menu-curtains-btn {
        display: flex;
        position: relative;
        align-items: center;
    }

    .menu-button,
    .def-link,
    .nav-right-container {
        display: none;
    }

    .nav-left-container {
        position: absolute;
        margin-left: 50%;
        transform: translateX(-70%);
        gap: 0px;
    }

    .img-nav-container img {
        height: 40px;
    }

    .product-img-pastille {
        top: 50px;
    }

    .product-img-pastille {
        font-size: 13px;
    }
}