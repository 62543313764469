.back-button-container {
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.devis-page {
    display: flex;
    flex-direction: column;
    gap: 80px;
}

.devis-page input:not([type="radio"]):not([type="checkbox"]),
.devis-page select {
    width: 300px;
}

.head-ask-devis {
    margin-bottom: 40px;
    display: inline-flex;
    padding: 10px 330px 20px 10px;
    align-items: flex-start;
    gap: 10px;
    border-bottom: 3px solid #000;
}

.body-ask-devis {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 720px;
}

.orga-items {
    padding: 7px 20px;
    gap: 10px;
    cursor: pointer;
    border: 1px solid #000;
    border-radius: var(--border-radius);
    transition: all var(--transition-speed);
}

.organisation-infos input[type="radio"] {
    display: none;
}

.orga-selected {
    background-color: black;
    color: white;
}

.input-devis-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 7px;
}

.input-devis-container label {
    font-size: var(--font-size-label);
}

.civility-devis-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 130px;
}

.civility-opt {
    display: flex;
    align-items: center;
}

.civility-opt label input[type="radio"] {
    margin-right: 10px;
}

.civility-opt label.errorInput {
    color: red;
}

.civility-opt input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 12px;
    height: 12px;
    border: 2px solid #000;
    border-radius: 50%;
}

.civility-opt input[type="radio"].errorInput {
    border: 1px solid red;
}

.civility-opt input[type="radio"]:checked {
    background-color: #000;
    border-color: #000;
}

.explain-projet-infos textarea {
    padding: 10px 5px;
    width: 750px;
    height: 200px;
    font-size: 15px;
    background: transparent;
    border: 1px solid #000;
}

/* Upload form */
.containerSectionFiles {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.upload {
    width: 150px;
    background-color: #ccc;
    color: #333;
    font-size: 14px;
    display: grid;
    border-radius: 8px;
    padding: 10px;
    gap: 10px;
}

.uploadBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 10px;
    border: 2px dashed #fff;
    border-radius: inherit;
    font-size: 12px;
}

.uploadBody svg {
    height: 50px;
    fill: #fff;
}

.uploadFooter {
    display: none;
}

.uploadButton {
    display: block;
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

/* content uploaded */
.uploadedImages {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.uploadedImages img {
    display: block;
    height: 80px;
}

.uploacontainer {
    position: relative;
}

.uploadedImagesEdit {
    position: relative;
}

.containerIconRemove,
.containerIconRemoveEdit {
    top: 5px;
    left: 5px;
    position: absolute;
}

.uploadedImages svg {
    height: 15px;
    fill: red;
    cursor: pointer;
}

.uploacontainer input {
    margin-top: 5px;
    border: 1px solid #000;
    padding: 2px 7px;
    background: transparent;
    border-radius: 10px;
    width: 80px;
}

/* End upload form */

.submit-devis-container {
    display: flex;
    justify-content: flex-end;
    width: 720px;
}

@media screen and (max-width: 834px) {
    .body-ask-devis {
        width: 650px;
    }

    .orga-items {
        padding: 5px 10px;
    }

    .explain-projet-infos textarea {
        width: 650px;
        height: 200px;
    }

    /* Upload form */

    .upload {
        width: 100px;
        font-size: 11px;
        padding: 5px;
    }

    .uploadBody {
        padding: 10px 5px;
        font-size: 10px;
    }

    .uploadBody svg {
        height: 30px;
    }

    .uploadButton {
        padding: 3px;
    }

    /* End upload form */

    .submit-devis-container {
        width: 650px;
    }
}

@media screen and (max-width: 480px) {
    .body-ask-devis {
        width: 100%;
    }

    .devis-page {
        gap: 50px;
    }

    .head-ask-devis {
        margin-bottom: 20px;
        padding: 10px 100px 10px 10px;
        border-bottom: 2px solid #000;
    }

    .head-ask-devis span {
        font-size: 13px;
    }

    .orga-items {
        padding: 3px 7px;
        font-size: 13px;
    }

    .input-devis-container input[type="text"],
    .input-devis-container input[type="email"],
    .input-devis-container select {
        width: 300px;
        font-size: 13px;
    }


    .explain-projet-infos textarea {
        width: 300px;
    }

    .uploadedImages img {
        height: 60px;
    }

    .submit-devis-container {
        width: 300px;
    }
}

@media screen and (max-width: 320px) {
    .body-ask-devis {
        gap: 5px;
    }

    .devis-page {
        gap: 30px;
    }

    .head-ask-devis span {
        font-size: 13px;
    }

    .orga-items {
        font-size: 12px;
    }

    .input-devis-container input[type="text"],
    .input-devis-container input[type="email"],
    .input-devis-container select {
        width: 200px;
    }

    .explain-projet-infos textarea {
        width: 200px;
    }

    .containerSectionFiles {
        margin-top: 10px;
    }

    .submit-devis-container {
        width: 200px;
    }
}